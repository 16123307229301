<template>
<div>
  <div class="site-main">
    <div class="image-slider">
      <Slider/>
    </div>
  </div>
</div>
</template>

<script>
import Slider from '@/components/Slider.vue'

export default {
  name: 'Home',
  components: {
    Slider
  },
  methods: {},
}
</script>

<style scoped>
.image-slider {
    width: 100%;
    height: 950px;
}
</style>
