<template>
<div>
  <div class="ttm-page-title-row">
      <div class="ttm-page-title-row-inner ttm-bgcolor-darkgrey">
          <div class="container">
              <div class="row align-items-center">
                  <div class="col-lg-12">
                      <div class="page-title-heading">
                          <h2 class="title">Contact Us</h2>
                      </div>
                      <div class="heading-seperator">
                          <span></span>
                      </div>
                  </div>
              </div>
          </div>
      </div>                    
  </div>
  <div class="site-main">
    <section class="ttm-row contact-us-section bg-layer-equal-height clearfix">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row no-gutters">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="border pt-35 pr-30 pb-40 pl-30">
                                <h5>Do You Have Any Questions?</h5>
                                <form id="contactform" class="contactform wrap-form pt-5 clearfix">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>
                                                <span class="text-input"><input name="name" v-model="contact.name" type="text"  placeholder="Your Name*" required="required"></span>
                                            </label>
                                        </div>
                                        <div class="col-md-6">
                                            <label>
                                                <span class="text-input"><input name="phone" v-model="contact.phone" type="text"  placeholder="Phone" required="required"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <label>
                                        <span class="text-input"><input name="email" type="text" v-model="contact.email" placeholder="Email Address*" required="required"></span>
                                    </label>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>
                                              <span class="text-input"><input name="eventDate" v-model="contact.eventDate" type="text"  placeholder="Your Event Date" required="required"></span>
                                            </label>
                                        </div>
                                        <div class="col-md-6">
                                            <label>
                                              <span class="text-input"><input name="guestCount" v-model="contact.guestCount" type="text"  placeholder="Guest Count" required="required"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <label>
                                        <span class="text-input"><textarea name="message" rows="4" v-model="contact.message" placeholder="Your Messages" required="required"></textarea></span>
                                    </label>
                                    <button class="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100" @click="OnSubmit(contact)" type="button">Send A Message!</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>             
        </div>
    </section>
  </div>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ContactUs',
  data() {
    return {
      contact: {
        name: '',
        phone: '',
        email: '',
        eventDate: '',
        guestCount: '',
        message: '',
        token: ''
      }
    }
  },
  components: {
  },
  methods: {
    async OnSubmit (contact) {
      contact.token = await this.$recaptcha('login')
      axios.post(`${process.env.VUE_APP_API_URL}send-email`, contact)
      .then(response => {
        this.$bvToast.toast("Your message has been sent successfully", {
          title: 'Message Sent',
          toaster: 'b-toaster-top-full',
          variant: 'success',
          solid: true,
          appendToast: true
        })
        console.log(response);
        this.contact = {
          name: '',
          phone: '',
          email: '',
          eventDate: '',
          guestCount: '',
          message: '',
          token: ''
        }
      })
      .catch(error => {
        console.log(error);
        this.$bvToast.toast("An error has occured please send email to thewhitebarnwedding@gmail.com", {
          title: 'Error',
          toaster: 'b-toaster-top-full',
          variant: 'warning',
          solid: true,
          appendToast: true
        })
      });
    }
  },
}
</script>

<style scoped>
.ttm-page-title-row { 
    background: url(https://wbw-bucket.s3.us-east-1.amazonaws.com/wbw-bucket1638657060929899.jpg);
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}
</style>
