<template>
<div>
  <div class="ttm-page-title-row">
      <div class="ttm-page-title-row-inner ttm-bgcolor-darkgrey">
          <div class="container">
              <div class="row align-items-center">
                  <div class="col-lg-12">
                      <div class="page-title-heading">
                          <h2 class="title">Packages</h2>
                      </div>
                      <div class="heading-seperator">
                          <span></span>
                      </div>
                  </div>
              </div>
          </div>
      </div>                    
  </div>
  <div class="site-main">
    <section class="ttm-row team-detail-section_1 clearfix">
        <div class="container">
          <div v-for="item in packagesWithArtifacts" :key="item.id">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ttm-team-member-single-content-wrapper">
                        <div class="row">
                            <div class="col-md-12 d-flex align-items-center">
                                <div class="ttm-team-member-single-list">
                                    <h2 class="ttm-team-member-single-title">{{item.title}}</h2>
                                    <h3 class="ttm-team-member-single-position ttm-textcolor-skincolor">Price: ${{item.price}}</h3>
                                    <div v-for="items in item.artifacts" :key="items.id">
                                      <p class="text-left"><b>{{items.title}}</b> - {{items.description}}</p>
                                      <hr>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>                 
            <hr>
          </div>
        </div>
    </section>
  </div>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Packages',
  data() {
    return {
      packages: {},
      packagesWithArtifacts: {},
      artifacts: {},
    };
  },
  mounted() {
    this.getPackages();
  },
  components: {
  },
  methods: {
    getPackages() {
        axios.get(`${process.env.VUE_APP_API_URL}packages/`)
        .then(response => {
            this.packages = response.data;
            axios.get(`${process.env.VUE_APP_API_URL}artifact/`)
            .then(response => {
                this.artifacts = response.data;
                this.combineArtifactsWithPackages(response.data);
            })
        })
        .catch(error => {
            console.log(error);
        });
    },
    combineArtifactsWithPackages(artifacts) {
          for (let j = 0; j < this.packages.length; j++) {
            this.packages[j].artifacts = [];
            for (let i = 0; i < artifacts.length; i++) {
                if (artifacts[i].package_id === this.packages[j].id) {
                    this.packages[j].artifacts.push(artifacts[i]);
                }
            }
        }
      this.packagesWithArtifacts = this.packages;
    },
  },
}
</script>

<style scoped>
.ttm-team-member-single-position {
    font-size: 24px;
    line-height: 26px;
    font-weight: 400;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #0000000f;
    font-family: 'Hind', sans-serif;
}
.ttm-team-member-single-content-wrapper {
    background-color: rgb(242, 250, 255);
}
.ttm-page-title-row { 
    background: url(https://wbw-bucket.s3.us-east-1.amazonaws.com/wbw-bucket1638655479931245.jpg);
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}
</style>
