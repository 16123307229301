<template>
  <div class="site-navigation d-flex flex-row align-items-center">
    <!-- site-branding -->
    <div class="site-branding">
      <a class="home-link" href="index.html" title="wedco" rel="home">
        <img id="logo-img" class="img-center standardlogo" src="@/assets/images/logo-white.png" alt="logo-img">
        <img id="logo-dark" class="img-center stickylogo" src="@/assets/images/logo-img-(1).png" alt="logo-img">
      </a>
    </div><!-- site-branding end -->
    <div class="btn-show-menu-mobile menubar menubar--squeeze" @click="showMenu()">
      <span class="menubar-box">
        <span class="menubar-inner"></span>
      </span>
    </div>
    <!-- menu -->
    <nav class="main-menu menu-mobile ml-auto" id="menu">
      <ul class="menu">
        <li v-bind:class="[isActive('Home') ? 'active' : '']">
          <a href="/" class="mega-menu-link">Home</a>
        </li>
        <li v-bind:class="[isActive('About') ? 'active' : '']">
          <a href="/about" class="mega-menu-link">About</a>
        </li>
        <li v-bind:class="[isActive('Packages') ? 'active' : '']">
          <a href="/packages" class="mega-menu-link">Packages</a>
        </li>
        <li v-bind:class="[isActive('Gallery') ? 'active' : '']">
          <a href="/gallery" class="mega-menu-link">Gallery</a>
        </li>
        <li v-bind:class="[isActive('Vendors') ? 'active' : '']">
          <a href="/vendors" class="mega-menu-link">Vendors</a>
        </li>
        <li v-bind:class="[isActive('Contact') ? 'active' : '']">
          <a href="/contact" class="mega-menu-link">Contact Us</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>

import $ from 'jquery';

export default {
  data () {
    return {}
  },
  methods: {
    showMenu () {
      $(this).toggleClass('is-active'); 
      $('.menu-mobile').toggleClass('show'); 
      return false;
    },
    isActive (path) {
      if (escape(this.$route.name) == escape(path)) {
        return true;
      }
    },
  },
}
</script>