<template>
<div>
  <div class="ttm-page-title-row">
    <div class="ttm-page-title-row-inner ttm-bgcolor-darkgrey">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="page-title-heading">
                        <h2 class="title">Vendors</h2>
                    </div>
                    <div class="heading-seperator">
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>                    
  </div>
  <div class="site-main">
    <section class="ttm-row event-section bg-img8 ttm-bg ttm-bgimage-yes clearfix">
        <div class="container">
            <!--row-->
            <div class="row">
                <div class="col-lg-6 col-md-8 col-sm-10 m-auto">
                    <!--section-title-->
                    <div class="section-title title-style-center_text">
                        <div class="title-header">
                            <h2 class="title">Recommended Vendors who are like family.</h2>
                        </div>
                    </div><!--section-title end-->
                </div>
            </div>
            <!--row end-->
            <div class="row mb_15">
                <div class="col-md-4" v-for="item in vendors" :key="item.id">
                    <!--featured-icon-box-->
                      <div class="featured-icon-box icon-align-top-content text-center style5">
                        <div class="featured-content">
                            <div class="featured-title">
                                <h6>{{item.name}}</h6>
                            </div>
                            <div class="featured-desc">
                                <p>{{item.poc}}</p>
                                <p>{{item.email}}</p>
                                <p>{{item.website}}</p>
                                <p>{{item.phone_number}}</p>
                            </div>
                        </div>
                        <a v-if="item.website" class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor ttm-icon-btn-right" :href="item.website">View Website</a>
                      </div><!--featured-icon-box end-->
                </div>
            </div>
        </div>
    </section>

    
    <!--event-section end-->
  </div>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Vendors',
  data() {
    return {
      vendors: {}
    };
  },
  mounted() {
    this.getVendors();
  },
  components: {
  },
  methods: {
      getVendors() {
          axios.get(`${process.env.VUE_APP_API_URL}vendors/`)
          .then(response => {
              this.vendors = response.data;
          })
          .catch(error => {
              console.log(error);
          });
      },
  },
}
</script>

<style scoped>
.image-slider {
    width: 100%;
    height: 950px;
}
.ttm-page-title-row { 
    background: url(https://wbw-bucket.s3.us-east-1.amazonaws.com/wbw-bucket1638655794834606.jpg);
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}
</style>
