<template>
<div>
    <div class="ttm-page-title-row">
      <div class="ttm-page-title-row-inner ttm-bgcolor-darkgrey">
          <div class="container">
              <div class="row align-items-center">
                  <div class="col-lg-12">
                      <div class="page-title-heading">
                          <h2 class="title">Gallery</h2>
                      </div>
                      <div class="heading-seperator">
                          <span></span>
                      </div>
                  </div>
              </div>
          </div>
      </div>                    
  </div>
  <div class="site-main">
      <section class="ttm-row contact-us-section bg-layer-equal-height clearfix">
          <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row no-gutters">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="pt-35 pr-30 pb-40 pl-30">
                              <b-carousel
                              id="wbw-gallery"
                              v-model="slide"
                              :interval="4000"
                              controls
                              indicators
                              background="#ababab"
                              img-width="1024"
                              img-height="480"
                              style="text-shadow: 1px 1px 2px #333;"
                              @sliding-start="onSlideStart"
                              @sliding-end="onSlideEnd"
                            >
                              <b-carousel-slide v-for="item in pictures" :key="item.id"
                                :img-src="item.picture_url"
                              ></b-carousel-slide>
                            </b-carousel>
                            <p class="text-white">
                              Credit: {{ pictures[slide].author }}
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>             
    </section>
  </div>
</div>
</template>

<script>
import axios from 'axios';

  export default {
    data() {
      return {
        slide: 0,
        sliding: null,
        pictures: [{
          author: '',
        }],
      }
    },
    mounted() {
      this.getPictures();
    },
    methods: {
      getPictures() {
          axios.get(`${process.env.VUE_APP_API_URL}pictures/`)
          .then(response => {
              this.pictures = response.data;
          })
          .catch(error => {
              console.log(error);
          });
      },
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      }
    }
  }
</script>

<style scoped>
.ttm-page-title-row { 
    background: url(https://wbw-bucket.s3.us-east-1.amazonaws.com/wbw-bucket1638656570996141.jpg);
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}
.site-main {
    background: #000000;
}
</style>
