<template>
<div>
    <!--page-title-->
        <div class="ttm-page-title-row">
            <div class="ttm-page-title-row-inner ttm-bgcolor-darkgrey">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="page-title-heading">
                                <h2 class="title">About Us</h2>
                            </div>
                            <div class="heading-seperator">
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                    
        </div>
        <!--page-title end-->
  <div class="site-main">
              <!--about-section_1-->
            <section class="ttm-row about-section_1 clearfix pb-50">
                <div class="container">
                    <!--row-->
                    <div class="row">
                        <div class="col-lg-6 col-sm-12">
                            <!--ttm_single_image-wrapper-->
                            <div class="ttm_single_image-wrapper">
                                <img class="img-fluid" src="https://wbw-bucket.s3.us-east-1.amazonaws.com/wbw-bucket1639063321212353.jpg" title="single-img-two" alt="single-img-two">
                            </div><!--ttm_single_image-wrapper end-->
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <div class="pt-35 pl-25 res-991-pl-0 res-991-pt-30">
                                <!--section title-->
                                <div class="section-title">
                                    <div class="title-header">
                                        <h5>Welcome to The White Barn Wedding</h5>
                                    </div>
                                    <div class="title-desc">
                                        <p>The White Barn is located 45 minutes north of Tampa in historic Brooksville, Florida. We want to welcome you to our home, and the original White Barn. It all started with Amanda and Branden’s wedding. The love story began in 2006 and the rest is history. We are family owned and operated and we will be here for all the planning meetings, rehearsals, and your wedding day. We pride ourselves in our reputation and look forward to helping you and your families plan a beautiful wedding. At The White Barn you start out as friends and leave as a part of our family. </p>
                                    </div>
                                </div><!--section title end-->
                                <p>We invite you to our completely pavered ceremony site surrounded by trees centered in front of either the hand built Oak Cross or arched Barn Door backdrop. Couple’s initials are monogrammed on the fence welcoming the guests during a wedding event. Appetizer and Cocktail hour are held in front of The White Barn on pavers. Receptions are held inside The White Barn surrounded by warm white led custom lighting and custom-tailored draping.</p>
                                <p> There are endless photography opportunities from the 100 year old brick chimney from the original farmhouse on site, to the majestic wooded areas. With only one wedding per day our wedding venue can ensure that the bride and groom receive our undivided attention and have a day that they will never forget.</p>
                            </div>
                        </div>
                    </div><!--row end-->
                </div>
            </section>
            <!--about-section_1 end-->

                        <!--introduction-section-->
            <section class="ttm-row introduction-section clearfix pt-50">
                <div class="container">
                    <div class="pt-45 pb-45 pl-30 pr-30 res-991-pl-15 res-991-pr-15 tes-991-pb-0 res-991-ml-15 res-991-mr-15 box-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-sm-12">
                            <!--section title-->
                            <div class="section-title clearfix">
                              <div class="pt-5">
                                    <!--featured-icon-box-->
                                    <div class="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                        <div class="featured-icon">
                                            <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                <i class="flaticon flaticon-balloons"></i>
                                            </div>
                                        </div>
                                        <div class="featured-content">
                                            <div class="featured-title">
                                                <h5>Wedding Wire Couples Choice Award</h5>
                                            </div>
                                            <div class="featured-desc">
                                                <p>The White Barn has won the 2016, 2017, 2018, 2019, 2020, 2021, and 2022 Wedding Wire Couples Choice Award and have been recognized amongst the top 5% of Wedding professionals nationwide.</p>
                                            </div>
                                        </div>
                                    </div><!--featured-icon-box end-->

                                     <div class="ttm-horizontal_sep mt-25 mb-30"></div>

                                    <!--featured-icon-box-->
                                    <div class="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                        <div class="featured-icon">
                                            <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                <i class="flaticon flaticon-love-birds"></i>
                                            </div>
                                        </div>
                                        <div class="featured-content">
                                            <div class="featured-title">
                                                <h5>Tampa's Best Wedding Venue</h5>
                                            </div>
                                            <div class="featured-desc">
                                                <p>The White Barn has also been featured in The Knot best of wedding venues and won Gold in Tampa Bay Times Best Wedding Venue.</p>
                                            </div>
                                        </div>
                                    </div><!--featured-icon-box end-->
                                    <div class="ttm-horizontal_sep mt-25 mb-30"></div>

                                    <!--featured-icon-box-->
                                    <div class="featured-icon-box icon-align-before-content icon-ver_align-top style4">
                                        <div class="featured-icon">
                                            <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                <i class="flaticon flaticon-wedding-rings-1"></i>
                                            </div>
                                        </div>
                                        <div class="featured-content">
                                            <div class="featured-title">
                                                <h5>Best of Brooksville Award</h5>
                                            </div>
                                            <div class="featured-desc">
                                                <p>We have been featured on Rustic Weddings as a Top Barn Wedding Venue in Florida. The White Barn has also been chosen for the Best of Brooksville awards in the category of Wedding Chapel. The best of Brooksville award was created to acknowledge the best businesses in our community.</p>
                                            </div>
                                        </div>
                                    </div><!--featured-icon-box end-->
                                </div>
                            </div><!--section title end-->
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <!-- ttm_single_image-wrapper -->
                            <div class="ttm_single_image-wrapper text-right ">
                                <img class="img-fluid" src="https://wbw-bucket.s3.us-east-1.amazonaws.com/wbw-bucket1639062695179312.jpg" alt="">
                            </div>
                        </div>
                    </div><!-- row end -->
                    </div>
                </div>
            </section>
            <!--introduction-section end-->
  </div>
</div>
</template>

<script>

export default {
  name: 'About',
  components: {
  },
  methods: {},
}
</script>

<style scoped>
.ttm-page-title-row { 
    background: url(https://wbw-bucket.s3.us-east-1.amazonaws.com/wbw-bucket1638654728129644.jpg);
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}
</style>
