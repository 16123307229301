<template>
  <footer class="footer widget-footer clearfix">
      <div class="bottom-footer-text">
          <div class="container">
              <div class="row copyright">
                  <div class="col-md-12">
                      <div class="text-center">
                          <span>Copyright ©{{year}}.&nbsp;All rights reserved by &nbsp;<a href="https://thewhitebarnwedding.com" target="_blank">The White Barn Wedding</a></span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </footer>
</template>

<script>

import moment from 'moment';

export default {
  name: 'footer',
    data() {
        return {
        year: moment().format('YYYY')
        }
    }
}
</script>