import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Template CSS Imports
import "@/assets/css/animate.css"
import "@/assets/css/flaticon.css"
import "@/assets/css/font-awesome.css"
import "@/assets/css/main.css"
import "@/assets/css/megamenu.css"
import "@/assets/css/prettyPhoto.css"
import "@/assets/css/responsive.css"
import "@/assets/css/slick.css"
import "@/assets/css/shortcodes.css"
import "@/assets/css/themify-icons.css"
import imagesLoaded from 'vue-images-loaded'
import 'slick-carousel/slick/slick.css';

import router from './router'
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.config.productionTip = false
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY })

new Vue({
  router,
  imagesLoaded,
  render: h => h(App)
}).$mount('#app')
