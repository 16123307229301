<template>
  <header id="masthead" class="header ttm-header-style-01">
    <div id="site-header-menu" class="site-header-menu ttm-bgcolor-darkgrey">
      <div class="site-header-menu-inner ttm-stickable-header">
        <div class="container">
          <NavBar/>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import $ from 'jquery';

export default {
  name: 'Header',
  components: {
    NavBar
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      if ( matchMedia( 'only screen and (min-width: 1200px)' ).matches ) 
      { if ($(window).scrollTop() >= 50 ) {
        $('.ttm-stickable-header').addClass('fixed-header');
        } else {
        $('.ttm-stickable-header').removeClass('fixed-header');
        }
      }
    },
  }
}
</script>
